<template>
  <div class="loginContainer">
    <img
      src="../../assets/loginBack.png"
      style="width: 100%; height: 100%; position: absolute; z-index: -1"
    />
    <div class="loginContentView" v-show="!isRegister">
      <img
        src="../../assets/loginLogo.png"
        style="width: 76%; margin: 0 auto; margin-top: 20%; display: block"
      />
      <div class="loginInputView" style="margin-top:12%">
        <el-input
          type="text"
          placeholder="Email"
          v-model.trim="form.student_email"
        ></el-input>
      </div>
      <div class="loginInputView" style="margin-top: 4%">
        <el-input
          type="password"
          placeholder="Password"
          v-model="form.student_pwd"
          @keyup.native.enter="submit"
        ></el-input>
      </div>
      <div class="loginBtn" @click="submit">Login</div>
      <div class="loginBtn" style="margin-top: 10px; background: #F65D4D" @click="isRegister = true">Register Now</div>
      <!--<div class="toRegister">
        <span @click="isRegister = true" style="cursor: pointer"
          >Register Now</span
        >
      </div>-->
    </div>
    <div class="loginContentView" v-show="isRegister">
      <div
        style="
          color: #0e4890;
          position: absolute;
          left: 30px;
          top: 30px;
          z-index: 999;
          display: flex;
          align-items: center;
        "
      >
        <i
          class="el-icon-arrow-left"
          style="
            border: 3px solid #0e4890;
            border-radius: 50%;
            font-weight: 900;
            font-size: 22px;
            cursor: pointer;
          "
          @click="isRegister = false"
        ></i>
        <span style="font-size: 22px; font-weight: bold; margin-left: 20px"
          >New Account Registration</span
        >
      </div>
      <div class="loginInputView1" style="margin-top: 14%">
        <el-input
          type="text"
          placeholder="Last Name"
          v-model="reForm.student_lastName"
        ></el-input>
      </div>
      <div class="loginInputView1">
        <el-input
          type="text"
          placeholder="First Name"
          v-model="reForm.student_givenName"
        ></el-input>
      </div>
      <div class="loginInputView1">
        <el-input
          type="text"
          placeholder="Email Address"
          v-model="reForm.student_email"
        ></el-input>
      </div>
      <div class="loginInputView1">
        <el-select
          style="width: 100%"
          type="text"
          placeholder="School"
          v-model="reForm.account_id"
          filterable
          remote
          clearable
          :remote-method="remoteMethod"
        >
          <template v-if="accounts.length > 0">
            <el-option
              v-for="(item, index) in accounts"
              :key="index"
              :label="item.account_name"
              :value="item._id.$id"
            ></el-option>
            <div>
              <div
                class="empty">
                没有搜索结果
              </div>
              <div class="report_school" @click="dialogShow">
                <img src="@/assets/query.png" style="height: 24px; width: 24px; margin-right: 10px;">
                <span>
                  Not Found？Report a new school to us.
                </span>
              </div>

            </div>
          </template>
          <template v-else >
            <div slot="empty">
              <div
                class="empty">
                没有搜索结果
              </div>
              <div class="report_school" @click="dialogShow">
                <img src="@/assets/query.png" style="height: 24px; width: 24px; margin-right: 10px;">
                <span>
                  Not Found？Report a new school to us.
                </span>
              </div>

            </div>
          </template>

        </el-select>
      </div>
      <div class="loginInputView1">
        <el-input
          type="password"
          placeholder="Password"
          v-model="reForm.student_pwd"
        ></el-input>
      </div>
      <div class="loginInputView1">
        <el-input
          type="password"
          placeholder="Confirm Password"
          v-model="reForm.student_pwd_again"
        ></el-input>
      </div>
      <div class="loginBtn" @click="register">Register</div>
    </div>

      <el-dialog
      :visible.sync="schoolForm.dialogVisible"
      width="813px"
      height="831px"
      center
      :show-close="false"
      custom-class="dialog_class"
      close-on-click-modal
      close-on-press-escape
    >
        <div
          style="font-size: 30px;font-family: DDINAlternateRegular;font-weight: bold;color: #F65D4D;text-align: center;"
        >
          添加学校
        </div>
        <div style="margin: 35px auto 0; width: 572px;">
          <el-input v-model="schoolForm.account_name" placeholder="school name"></el-input>
        </div>
      <div style="margin: 35px auto 0; width: 572px;">
        <el-input v-model="schoolForm.country" placeholder="country"></el-input>
      </div>
      <div style="margin: 35px auto 0; width: 572px;">
        <el-input v-model="schoolForm.province" placeholder="province"></el-input>
      </div>
        <div style="margin: 35px auto 0; width: 572px;">
          <el-input v-model="schoolForm.city" placeholder="city" ></el-input>
        </div>
      <div style="margin: 35px auto 0; width: 572px;">
        <el-input v-model="schoolForm.address" placeholder="address" ></el-input>
      </div>
      <div style="margin: 35px auto 0; width: 572px;">
        <el-input v-model="schoolForm.website" placeholder="website" slot="footer"></el-input>
      </div>
      <div
        style="width: 475px;height: 50px;background: #0E4890;border-radius: 50px;color: #FFFFFF;line-height:50px;text-align: center;margin: auto; cursor: pointer; margin-top: 120px; margin-bottom: 100px;"
        @click="subSchoolForm()">确认添加</div>
    </el-dialog>

  </div>
</template>

<script>
import { setUserId, setUserName, setUserAccountID } from '../../utils/store.js'
import {
  loginByStudent,
  addStudentInDatabase,
  searchSchool,
  reportNewSchool
} from '../../api/index'
import '../../assets/common/font.css'
export default {
  name: 'Login',
  data () {
    return {
      form: {
        student_email: '',
        student_pwd: ''
      },
      reForm: {
        account_id: '',
        student_lastName: '',
        student_givenName: '',
        student_lastName_pinyin: '',
        student_givenName_pinyin: '',
        student_gender: '',
        student_graduation: '',
        student_pwd: '',
        student_mobile: '',
        student_email: '',
        student_pwd_again: ''
      },
      accounts: [],
      isRegister: false,
      schoolForm: {
        dialogVisible: false,
        account_name: '',
        country: '',
        province: '',
        city: '',
        address: '',
        website: ''
      }
    }
  },
  mounted () {
    console.log('test jenkins')
  },
  methods: {
    refreshReForm () {
      this.reForm = {
        account_id: '',
        student_lastName: '',
        student_givenName: '',
        student_lastName_pinyin: '',
        student_givenName_pinyin: '',
        student_gender: '',
        student_graduation: '',
        student_pwd: '',
        student_mobile: '',
        student_email: '',
        student_pwd_again: ''
      }
    },
    submit () {
      loginByStudent(this.form.student_email, this.form.student_pwd).then(
        (res) => {
          if (res.data.code === 0) {
            setUserId(res.data.data._id.$id)
            setUserAccountID(res.data.data.account_id)

            var lastName = res.data.data.student_lastName ? res.data.data.student_lastName : ''
            var givenName = res.data.data.student_givenName ? res.data.data.student_givenName : ''

            setUserName(lastName + givenName)
            this.$router.push('/pie')
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg ? res.data.msg : 'login fail')
          }
        }
      )
    },
    remoteMethod (e) {
      searchSchool(e).then((res) => {
        this.accounts = res.data
      })
    },
    register () {
      if (!this.reForm.student_lastName) {
        this.$message.error('Please input lastName')
        return
      }
      if (!this.reForm.student_givenName) {
        this.$message.error('Please input givenName')
        return
      }
      if (!this.reForm.student_email) {
        this.$message.error('Please input email')
        return
      }
      if (!/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(this.reForm.student_email)) {
        this.$message.error('Please input correct Email format')
        return
      }
      if (!this.reForm.student_pwd) {
        this.$message.error('Please input password')
        return
      }
      if (!this.reForm.account_id) {
        this.$message.error('Please select school')
        return
      }
      if (this.reForm.student_pwd !== this.reForm.student_pwd_again) {
        this.$message.error('The two passwords are inconsistent')
        return
      }

      addStudentInDatabase(
        this.reForm.account_id,
        this.reForm.student_lastName,
        this.reForm.student_givenName,
        this.reForm.student_lastName_pinyin,
        this.reForm.student_givenName_pinyin,
        this.reForm.student_gender,
        this.reForm.student_graduation,
        this.reForm.student_pwd,
        this.reForm.student_mobile,
        this.reForm.student_email
      ).then((res) => {
        if (res.data.code === 0) {
          this.$message.success('Register success')
          this.refreshReForm()
          this.isRegister = false
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    subSchoolForm () {
      if (!this.schoolForm.account_name) {
        this.$message.error('Please select school name')
        return
      }
      if (!this.schoolForm.country) {
        this.$message.error('Please input country')
        return
      }
      if (!this.schoolForm.province) {
        this.$message.error('Please input province')
        return
      }
      if (!this.schoolForm.city) {
        this.$message.error('Please input city')
        return
      }
      if (!this.schoolForm.address) {
        this.$message.error('Please select address')
        return
      }
      if (!this.schoolForm.website) {
        this.$message.error('Please select website')
        return
      }

      reportNewSchool(
        this.schoolForm.account_name,
        this.schoolForm.country,
        this.schoolForm.province,
        this.schoolForm.city,
        this.schoolForm.address,
        this.schoolForm.website
      ).then((res) => {
        if (res.data.code === 0) {
          console.log(res)
          this.$message.success('Report success')
          this.schoolForm.dialogVisible = false
          this.accounts.push(res.data.msg)
          this.reForm.account_id = res.data.msg._id.$id
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    dialogShow () {
      console.log(123)
      this.schoolForm.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.loginContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #909399;
  overflow: hidden;
}
.loginBackImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}
.loginContentView {
  width: 43%;
  height: 100%;
  position: relative;
  background: #f8fcff;
  .label {
    width: 70px;
  }
}
.loginTitle {
  text-align: center;
  font-size: 22px;
  font-family: "DDINAlternateRegular";
  font-weight: bold;
  color: #606266;
  margin-top: 40px;
}
.loginIcon {
  width: 30px;
}
.loginInputView {
  width: 70%;
  margin: 0 auto;
  margin-top: 7%;
  display: flex;
  align-items: center;
}
.loginInputView1 {
  width: 70%;
  margin: 0 auto;
  margin-top: 4%;
  display: flex;
  align-items: center;
}
.loginBtn {
  width: 70%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0 auto;
  margin-top: 22%;

  border-radius: 6px;
  font-family: "DDINAlternateRegular";
  font-weight: bold;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  background: #0e4890;
  font-family: "DINAlternateBold";
}
.toRegister {
  color: #0e4890;
  width: 70%;
  height: 40px;
  text-align: right;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 14px;
}
.loginBtn:hover {
  opacity: 0.8;
}
.loginContainer {
  ::v-deep .el-input__inner {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    height: 48px;
    border: 2px solid #717171;
  }
  ::v-deep .el-input__inner:focus {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
  ::v-deep .el-input__inner:hover {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
  ::v-deep .el-select:hover .el-input__inner {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
  ::v-deep .el-select .el-input.is-focus .el-input__inner {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
  ::v-deep .el-select .el-input__inner:focus {
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    border: 2px solid #717171;
  }
}

  .report_school {
    font-size: 14px;
    font-family: "DDINAlternateRegular";
    font-weight: 500;
    color: #FFB414;
    border-bottom: 1px solid #F2F2F2;
    width: 90%;
    margin: auto;
    padding: 18px 0 20px;
    display: flex;
    align-items: center;
    justify-items: center;
    cursor: pointer;
  }
  .empty{
    font-size: 14px;font-family: "DDINAlternateRegular";font-weight: bold;color: #A4A4A4;
    border-bottom: 1px solid #F2F2F2; width: 90%; margin: auto; padding: 17px 0 15px;
  }
  .dialog_class{
    border-radius: 15px !important;
  }
</style>
