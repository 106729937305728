<template>
  <div class="homeContainer">
    <span class="userName">{{'Hello,'+user_name}}</span>
    <div @click="logout" class="logoutStyle">Log out</div>
    <!-- <img
      src="../assets/exit.svg"
      @click="logout"
      style="
        width: 30px;
        position: absolute;
        right: 26px;
        top: 22px;
        z-index: 20;
        cursor: pointer;
      "
    /> -->
    <div class="leftSide">
      <img src="../assets/newLogo.png" style="margin: 38px auto 53px auto; display: block;width: 80px;" />
      <el-menu :router="true" :default-active="$route.path">
      <el-menu-item
          index="/pie"
          :route="{ path: '/pie' }"
          style="text-align: left"
          ><img v-if="$route.path == '/pie'" src="../assets/pieSelect.png" class="icon"><img v-else src="../assets/pie.png" class="icon">My PIE Entries</el-menu-item
        >
        <el-menu-item
          index="/poe"
          :route="{ path: '/poe' }"
          style="text-align: left"
          ><img v-if="$route.path == '/poe'" src="../assets/poeSelect.png" class="icon"><img v-else src="../assets/poe.png" class="icon">My POE Entries</el-menu-item
        >
        <el-menu-item
          index="/center"
          :route="{ path: '/center' }"
          style="text-align: left"
          ><img v-if="$route.path == '/center'" src="../assets/centerSelect.png" class="icon"><img v-else src="../assets/center.png" class="icon">My Creation Entries</el-menu-item
        >
        <el-menu-item
          index="/messages"
          :route="{ path: '/messages' }"
          style="text-align: left"
        ><img v-if="$route.path == '/messages'" src="../assets/msgSelect.png" class="icon"><img v-else src="../assets/msg.png" class="icon">Messages</el-menu-item
        >
        <el-menu-item
          index="/set"
          :route="{ path: '/set' }"
          style="text-align: left"
          ><img v-if="$route.path == '/set'" src="../assets/setSelect.png" class="icon"><img v-else src="../assets/set.png" class="icon">Settings</el-menu-item
        >
      </el-menu>
    </div>
    <div class="right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { removeUserId, getUserName } from '../utils/store.js'
import '../assets/common/font.css'
export default {
  data () {
    return {
      user_name: getUserName()
    }
  },
  mounted () {},
  beforeDestroy () {},
  methods: {
    logout () {
      this.$confirm('Confirm to log out?', 'Notice', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then((res) => {
        removeUserId()
        location.reload()
      })
    }
  }
}
</script>

<style lang="scss">
.homeContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  overflow: hidden;
  background: #f5f9ff;
  font-family: "DDINAlternateRegular";
  .leftSide {
    height: 100%;
    width: 260px;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 2px 0px 12px 1px rgba(96, 91, 255, 0.07);
    .el-menu {
      border: none;
      background: none;
    }
    .el-menu-item.is-active {
      background: #ffb414;
      color: #fff !important;
    }
    .el-menu-item:focus,
    .el-menu-item:hover {
      background: #ffb414;
      color: #fff !important;
    }
    .icon{
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }
  }
  .right {
    margin-left: 40px;
    flex: 1;
    height: 100%;
    background: #fff;
    overflow: hidden;
    position: relative;
  }
}
.userName{
  //  width: 30px;
    position: absolute;
    right: 180px;
    top: 25px;
    z-index: 20;
    cursor: pointer;
    font-size: 25px;
    font-weight: 600;
    color: #0E4890;
}
.logoutStyle{
  width: 100px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  right: 26px;
  top: 25px;
  z-index: 20;
  color: #0E4890;
  border: 2px solid #0e4890;
  border-radius: 5px;
  text-align: center;
}

</style>
