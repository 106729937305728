import Cookies from 'js-cookie'

export function setUserId (user_id) {
  Cookies.set('mr_user_id', user_id)
}
export function getUserId () {
  return Cookies.get('mr_user_id')
}

export function setUserName (user_name) {
  Cookies.set('mr_user_name', user_name)
}
export function getUserName () {
  return Cookies.get('mr_user_name')
}

export function setUserAccountID (user_name) {
  Cookies.set('mr_user_account_id', user_name)
}
export function getUserAccountID () {
  return Cookies.get('mr_user_account_id')
}

export function removeUserId () {
  Cookies.remove('mr_user_id')
}
export function getProject () {
  return '22-23MediaReview'
}
