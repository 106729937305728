import Vue from 'vue'
import Router from 'vue-router'
import { getUserId } from '../utils/store.js'
import Login from '../components/Login/index.vue'
import Home from '../views/Home.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/center',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: '/login',
      component: Login,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/apply',
      name: 'Apply',
      component: () => import('../views/Apply'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/create',
      name: 'Create',
      component: () => import('../views/Create'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/edit',
      name: 'Edit',
      component: () => import('../views/Edit'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/confirm',
      name: 'Confirm',
      component: () => import('../views/Confirm'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/join',
      name: 'Join',
      component: () => import('../views/Join'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/application',
      name: 'Application',
      component: () => import('../views/Application'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/pghome',
      name: 'pghome',
      component: () => import('../views/pg/PGHome.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/toPrint',
      name: 'toPrint',
      component: () => import('../views/pg/ToPrint.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/appform',
      name: 'appform',
      component: () => import('../views/appform.vue')
    },
    {
      path: '/accountform',
      name: 'accountform',
      component: () => import('../views/accountform/index.vue')
    },
    {
      path: '/qrcode',
      name: 'qrcode',
      component: () => import('../views/qrcode.vue')
    },
    {
      path: '/pay',
      name: 'pay',
      component: () => import('../views/pay.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/pghead',
      name: 'pghead',
      component: () => import('../views/pg/Head.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/pgquiz',
      name: 'pgquiz',
      component: () => import('../views/pg/Quiz.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/payment',
      name: 'payment',
      component: () => import('../views/paymentT.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/set',
          name: 'Set',
          component: () => import('../views/Set'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/center',
          name: 'Center',
          component: () => import('../views/Center'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/detail',
          name: 'Detail',
          component: () => import('../views/Detail'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/messages',
          name: 'Messages',
          component: () => import('../views/Messages'),
          meta: {
            requiresAuth: true
          }
        }, {
          path: '/pie',
          name: 'pie',
          component: () => import('../views/pie.vue'),
          meta: {
            requiresAuth: true
          }
        }, {
          path: '/poe',
          name: 'poe',
          component: () => import('../views/poe.vue'),
          meta: {
            requiresAuth: true
          }
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    const userId = getUserId()
    if (to.meta.requiresAuth && !userId) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  }
})
export default router
