import request from '../utils/request'

export function loginByStudent (student_email, student_pwd) {
  return request.post('./index.php', {
    function: 'loginByStudent',
    student_email,
    student_pwd
  })
}

export function searchSchool (name) {
  return request.post('./index.php', {
    function: 'searchSchool',
    name
  })
}
export function addSchool (
  account_id,
  lastName,
  givenName,
  lastName_pinyin,
  givenName_pinyin,
  gender,
  graduation,
  student_pwd,
  mobile,
  email
) {
  return request.post('./index.php', {
    function: 'addStudentInDatabase',
    account_id,
    lastName,
    givenName,
    lastName_pinyin,
    givenName_pinyin,
    gender,
    graduation,
    student_pwd,
    mobile,
    email
  })
}

export function addStudentInDatabase (
  account_id,
  lastName,
  givenName,
  lastName_pinyin,
  givenName_pinyin,
  gender,
  graduation,
  student_pwd,
  mobile,
  email
) {
  return request.post('./index.php', {
    function: 'addStudentInDatabase',
    account_id,
    lastName,
    givenName,
    lastName_pinyin,
    givenName_pinyin,
    gender,
    graduation,
    student_pwd,
    mobile,
    email
  })
}

export function reportNewSchool (
  account_name,
  country,
  province,
  city,
  address,
  website
) {
  return request.post('./index.php', {
    function: 'reportNewSchool',
    account_name,
    country,
    province,
    city,
    address,
    website
  })
}

export function getStudentApplications (student_id, project_code, program) {
  return request.post('./index.php', {
    function: 'getStudentApplications',
    student_id,
    project_code,
    program
  })
}
export function updateEntry (param) {
  return request.post('./index.php', {
    function: 'updateEntry',
    ...param
  })
}

export function getQNToken () {
  return request.post('./index.php', {
    function: 'getQNToken'
  })
}

export function getMREntry (id) {
  return request.post('./index.php', {
    function: 'getMREntry',
    id
  })
}

export function getEntryApplications (entry_id) {
  return request.post('./index.php', {
    function: 'getEntryApplications',
    entry_id
  })
}

export function addNewApplication (entry_id, pwd, role, program) {
  return request.post('./index.php', {
    function: 'addNewApplication',
    entry_id,
    pwd,
    role,
    program
  })
}

export function getApplicationByID (id) {
  return request.post('./index.php', {
    function: 'getApplicationByID',
    id
  })
}

export function getEntryByCode (code) {
  return request.post('./index.php', {
    function: 'getEntryByCode',
    code
  })
}

export function getMyMessages (id, project) {
  return request.post('./index.php', {
    function: 'getMyMessages',
    id,
    project
  })
}
export function sendMessage (userId, project, content, type, accessory) {
  return request.post('./index.php', {
    function: 'sendMessage',
    userId,
    project,
    content,
    type,
    accessory
  })
}
export function changePassword (id, initial_password, new_password, new_password_again) {
  return request.post('./index.php', {
    function: 'changePassword',
    id,
    initial_password,
    new_password,
    new_password_again
  })
}

export function getPaypal (application_id) {
  return request.post('./index.php', {
    function: 'getPaypal',
    application_id
  })
}

export function paypalSucceedBack (application_id, data) {
  return request.post('./index.php', {
    function: 'paypalSucceedBack',
    application_id,
    data
  })
}

export function getStudentInfoByID (id) {
  return request.post('./index.php', {
    function: 'getStudentInfoByID',
    id
  })
}

export function updateStudentName (_id, lastName, givenName) {
  return request.post('./index.php', {
    function: 'updateStudentName',
    _id,
    lastName,
    givenName
  })
}

export function saveAppForm (form) {
  return request.post('./index.php', {
    function: 'saveAppForm',
    form
  })
}
export function getPaymentByApplication (application_id) {
  return request.post('./index.php', {
    function: 'getPaymentByApplication',
    application_id
  })
}

export function getAccount (account_id) {
  return request.post('./index.php', {
    function: 'getAccount',
    account_id
  })
}

export function saveAccountForm (form) {
  return request.post('./index.php', {
    function: 'saveAccountForm',
    form
  })
}
export function getAccounts () {
  return request.post('./index.php', {
    function: 'getAccounts'
  })
}
